.GoalsAndStrategies {
  padding: 0 34px;
}

.GoalsAndStrategies .page-title {
  font-family: "DM Serif Display", serif;
  font-size: 40px;
  font-weight: 400;
  color: #274472;
  margin-top: 37px;
  margin-bottom: 0;
}

.goals-grid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.component-grid-item {
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .GoalsAndStrategies {
    padding: 0 15px;
  }
}

@media screen and (max-width: 680px) {

  .goals-grid {
    margin-top: 0px;
    display: grid;
    grid-template-columns: 1fr;
  
  }
  
  .component-grid-item {
    width: 100%;
    text-align: center;
    padding: 0px;
  }
}
