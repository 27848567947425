.select-cornerpiece {
  background-color: white;
}

.select-cornerpiece-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 5em;
}

.select-cornerpiece-container h1 {
  font-family: 'PT Sans', sans-serif;
  font-size: 30pt;
  font-weight: 400;
  margin: 0;
}

.select-cornerpiece-view-container {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #f2faff;
  border: solid 1px #d5e7f1;
  border-radius: 20px;
  padding: 20px 0;
}

.select-cornerpiece-view-cornerpiece {
  padding: 1em 2em;
  width: 100%;
  height: auto;
  background-color: transparent;
  font-family: "Gothic A1";
  border-top: solid 1px #d5e7f1;
}

.select-cornerpiece-view-cornerpiece:hover {
  background-color: #397da2;
}

.select-cornerpiece-view-cornerpiece:last-child {
  border-bottom: solid 1px #d5e7f1;
}

.select-cornerpiece-cornerpiece-name {
  font-family: 'PT Sans', sans-serif;
  font-size: x-large;
  width: 100%;
  text-align: center;
  margin: 0;
  cursor: pointer;
  display: inline-block;
}

.select-cornerpiece-view-cornerpiece:hover .select-cornerpiece-cornerpiece-name {
  color: white;
}

@media screen and (max-width: 420px) {
  .select-cornerpiece {
    padding: 0 15px;
  }
}