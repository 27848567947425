.TargetedMessaging {
  padding: 0 34px;
}

.TargetedMessaging .page-title {
  font-family: "DM Serif Display", serif;
  font-size: 40px;
  font-weight: 400;
  color: #274472;
  margin-top: 37px;
}

.user-persona-dropdown {
  display: flex;
  flex: start;
  margin-top: 30px;
}

.user-persona-dropdown-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-left: 15px;
  border: solid 1px #91b6cb;
  border-radius: 10px;
  background-color: #e4f0f7;
  cursor: pointer;
}

.user-persona-dropdown-main:hover {
  background-color: #e6f6ff;
}

.user-persona-dropdown-main h2 {
  color: #274472;
  font-family: "DM Serif Display", serif;
  font-size: 20px;
  margin: 0;
}

.user-persona-dropdown-main img {
  color: #274472;
  height: 30px;
  margin-left: 10px;
}

.user-persona-dropdown-items {
  display: block;
  position: absolute;
  padding: 10px;
  border: solid 1px #91b6cb;
  border-radius: 10px;
  background-color: #e4f0f7;
}

.user-persona-dropdown-items ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.user-persona-dropdown-items li {
  /* padding: 10px 5px 5px; */
  font-size: 18px;
  font-family: "DM Serif Display", serif;
  border-bottom: 2px solid #91b6cb;
  color: #274472;
  cursor: pointer;
  margin-bottom: 10px;
}

.user-persona-dropdown-items li:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.user-persona-dropdown-items li img {
  display: none;
}

.user-persona-dropdown-items-selected img {
  display: inline;
}

.messaging-container {
  background: none;
  border-radius: none;
}

@media screen and (max-width: 600px) {
  .TargetedMessaging {
    padding: 0 15px;
  }
}

@media screen and (max-width: 680px) {
  .messaging-container {
    background-color: #e4f0f7;
    border-radius: 10px;
  }

  .user-persona-dropdown-container {
    width: 100%;
  }

  .user-persona-dropdown-items {
    display: block;
    position: relative;
  }

  .user-persona-dropdown-main:hover {
    background-color: #e4f0f7;
  }

  .user-persona-dropdown-items {
    background-color: #e4f0f7;
  }
}
