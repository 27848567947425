.admin-page-view-container {
  width: 100%;
}

.admin-page-view-users {
  padding: 1em 2em;
  border-bottom: solid 1px #d5e7f1;
  width: 100%;
  height: auto;
  background-color: transparent;
  font-family: "Gothic A1";
  position: relative;
}

.user-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.user-info {
  display: flex;
}

.user-info-email {
  font-family: 'PT Sans', sans-serif;
  font-size: large;
  margin: 0;
  vertical-align: middle;
  display: inline-block;
  align-self: center;
}

.admin-button-container {
  display: flex;
  align-items: center;
}

.admin-button-container select {
  /* styling */
  background-color: white;
  border: thin solid black;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  margin-right: 20px;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

.admin-button-container select:focus {
  outline-width: 0;
}

.edit-button {
  margin-top: 0;
  width: 50px;
  height: 30px;
  border: none;
  background-color: transparent;
}

.delete-button {
  margin-top: 0;
  width: 50px;
  height: 30px;
  border: none;
  background-color: transparent;
}

.edit-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.delete-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
