.super-admin-page-edit-container {
  width: 100%;
  background-color: #f2faff;
  border-radius: 10px;
  padding: 10px 0;
}

.super-admin-edit-input-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  width: 100%;
  height: 10%;
  margin-bottom: 10px;
}

.super-admin-page-edit-container input {
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 10px;
}

.super-admin-page-edit-container input:focus {
  outline-width: 0;
}

.super-admin-edit-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
  width: 100%;
}

.superadmin-edit-btn {
  width: 120px;
  height: 50%;
  color: #284159;
  font-weight: bold;
  font-family: "Gothic A1";
  border-radius: 10px;
  background-color: #d5e7f1;
  border: solid 1px #284159;
}

.submit-edit-btn {
  color: #284159;
  border: solid 1px #284159;
}

.close-edit-btn {
  color: #ff3131;
  border: solid 1px #ff3131;
}
