.super-admin-page-view-container {
  width: 100%;
  background-color: #f2faff;
}

.super-admin-page-view-cornerpiece {
  padding: 1em 2em;
  border-bottom: solid 1px #d5e7f1;
  width: 100%;
  height: auto;
  background-color: transparent;
  font-family: "Gothic A1";
  position: relative;
}

.cornerpiece-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cornerpiece-content .cornerpiece-name {
  font-family: 'PT Sans', sans-serif;
  font-size: x-large;
  margin: 0;
  cursor: pointer;
  background-color: #ffd154;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
}

.cornerpiece-content .cornerpiece-client-name {
  font-family: 'PT Sans', sans-serif;
  font-size: large;
  text-decoration: none;
  margin: 0;
}

.button-container {
  display: flex;
  align-items: center;
}

.open-button {
  text-decoration: underline;
  text-underline-offset: 4px;
  background: none;
  border: none;
  font-family: 'IvyPresto', sans-serif;
}

.edit-button {
  margin-top: 0;
  width: 50px;
  height: 30px;
  border: none;
  background-color: transparent;
}

.delete-button {
  margin-top: 0;
  width: 50px;
  height: 30px;
  border: none;
  background-color: transparent;
}

.edit-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.delete-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
