.Error {
  width: 100%;
  height: 80vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-container {
  margin: 0 auto;
  align-self: center;
  width: 100%;
  max-width: 400px;
  padding: 30px 30px;
  background-color: #f2faff;
  border: solid 1px #d5e7f1;
  border-radius: 20px;
}

.error-title {
  width: 100%;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  font-size: xx-large;
  font-weight: 700;
}

.error-subtitle {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  font-size: large;
  font-weight: 700;
}