.admin-page {
  background-color: white;
}

.admin-page-container {
  width: 100%;
  max-width: 725px;
  margin: 30px auto;
}

.admin-page-header {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.admin-page-header h1 {
  font-family: 'DM Serif Display', serif;
  font-size: 28pt;
  text-align: left;
  margin: 0;
}

.admin-return-buttom {
  border: none;
  background: none;
  background-color: #274472;
  color: #FCFBFA;
  border-radius: 10px;
  padding: 0 15px;
}

.admin-page-container-header {
  display: flex;
  background-color: #ffde83;
  align-items: center;
  width: 100%;
  height: 20%;
  padding-left: 2%;
  padding-right: 2%;
}

.admin-page-container-header h2 {
  margin-bottom: 0;
  margin-left: 20px;
  font-size: 2.2rem;
  color: black;
  font-family: 'DM Serif Display', serif;
}

.admin-page-container-header img {
  width: 150px;
  height: 150px;
}

.admin-page-users-container {
  background-color: #FAF3DE;
}

.admin-page-add-button {
  display: block;
  margin: 0 auto;
  background-color: #ffde83;
  border-radius: 10px;
  border: none;
}

.admin-page-container-footer {
  margin-top: 20px;
}
