.CoreComponents {
  padding: 0 34px;
}

.CoreComponents .page-title {
  font-family: "DM Serif Display", serif;
  font-size: 40px;
  font-weight: 400;
  color: #274472;
  margin-top: 37px;
}

.KeyValueProps {
  margin-top: 30px;
}

.core-components-grid {
  display: grid;
  height: fit-content;
  margin-top: 50px;
  margin-bottom: 50px;
}

.core-components-grid-logo-container {
  display: inline-block;
  margin-right: 10px;
}

.core-components-grid-logo {
  height: 100px;
  width: 100px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.core-components-grid-info {
  display: inline-block;
  vertical-align: middle;
  width: 80%;
}

.core-components-grid-info h3 {
  font-family: "DM Serif Display";
  color: #274472;
  margin: 0;
}

.core-components-grid-info p {
  font-family: "PT Sans";
  color: #274472;
  margin: 0;
}

@media screen and (max-width: 680px) {
  .core-components-grid {
    margin-top: 15px;
    margin-bottom: 50px;
  }
  .core-components-grid-info {
    width: 100%;
    text-align: center;
  }
  .core-components-grid-logo-container {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-right: 0;
  }
  .core-components-grid-logo {
    height: 75px;
    width: 75px;
    margin-right: 0;
  }
}

@media screen and (max-width: 600px) {
  .CoreComponents {
    padding: 0 15px;
  }
}