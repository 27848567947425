.homepage-navbar {
  display: flex;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5%;
  background-color: #ffd154;
}

.navbar-toggle-button {
  margin-right: auto;
  height: 50px;
  width: 60px;
  background-color: #ffd154;
  border-style: none;
  border-top-left-radius: 30%;
  border-top-right-radius: 30%;
}

.navbar-toggle {
  height: 35px;
  width: 35px;
}

.navbar-toggle-button-open {
  background-color: #ffde83;
}

.navbar-right-content {
  display: flex;
  align-items: center;
}

.navbar-open {
  align-items: center;
  padding: 15px 0;
  background-color: #ffde83;
}

.navbar-menu {
  align-self: flex-start;
  width: 150px;
  margin-left: 5%;
  font-size: 14pt;
  font-family: "DM Serif Display";
  font-weight: bold;
  border-bottom: #274472 1px solid;
  color: #274472;
}

.navbar-links-container {
  display: block;
  width: 100%;
}

.navbar-links {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.navbar-link {
  font-size: 16pt;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  text-decoration: none;
  color: #274472;
  border: none;
  background: none;
}

.navbar-link:hover {
  color: #397DA2;
  text-decoration: underline;
}

.navbar-link-selected {
  font-size: 16pt;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #274472;
  border: none;
  background: none;
}

.navbar-search {
  position: relative;
  margin-right: 30px;
}

.navbar-search-input {
  height: 35px;
  padding: 12px;
  padding-left: 30px;
  width: 250px;
  font-size: 10px;
  border-radius: 75px;
}

.navbar-search-btn {
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  opacity: 0.5;
}

.navbar-account-button {
  background: none;
  border: none;
}

.navbar-account-img {
  height: 35px;
  width: 35px;
}

.navbar-account-dropdown {
  display: inline;
  position: absolute;
  top: 55px;
  right: 5%;
  background-color: white;
  border: 1px solid #274472;
  border-radius: 10px;
  padding: 10px 0;
}

.navbar-account-dropdown-button {
  display: block;
  background: none;
  border: none;
  padding: 10px 20px;
  color: #274472;
  font-family: "PT Sans", sans-serif;
  font-size: 14pt;
  width: 100%;
}

.navbar-account-dropdown-button:hover {
  background-color: #274472;
  color: white;
}

@media screen and (max-width: 600px) {
  .navbar-links {
    display: grid;
    gap: 10px;
  }
}