.super-admin-page-add-container {
  align-items: center;
  width: 100%;
  background-color: #f2faff;
  
}

.super-admin-page-add-form-container {
  width: 100%;
  background-color: transparent;
  padding: 20px 20px;
  border-bottom: solid 1px #d5e7f1;
}

.super-admin-page-add-form {
  width: 100%;
  font-family: "Gothic A1 Light";
}

.super-admin-input-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 1em;
}

.super-admin-page-add-form input {
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px 10px;
}

.super-admin-page-add-form input:focus {
  outline-width: 0;
}

.super-admin-page-add-form h4 {
  margin-top: 20px;
}

.super-admin-page-add-form-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
  width: 100%;
}

.superadmin-add-btn {
  width: 80px;
  height: 50%;
  color: #284159;
  font-weight: bold;
  font-family: "Gothic A1";
  border-radius: 10px;
  background-color: #d5e7f1;
  border: solid 1px #284159;
}

.submit-btn {
  color: #284159;
  border: solid 1px #284159;
}

.close-btn {
  color: #ff3131;
  border: solid 1px #ff3131;
}
