.MoreAbout {
  margin: 50px 0;
  padding: 30px 40px 20px 40px;
  border-radius: 10px;
  background-color: #e4f0f7;
  border: 1px solid #a3cde4;
}

.more-about-mobile {
  display: none;
}

.MoreAbout h2 {
  color: #274472;
  font-family: "DM Serif Display", sans-serif;
  font-size: 30px;
  margin: 0;
}

.MoreAbout h3 {
  font-family: "DM Serif Display", sans-serif;
  font-weight: 700;
  font-size: 25px;
  color: #274472;
  margin: 0;
}

.Bio,
.Motivations,
.Frustrations {
  display: flex;
  margin-top: 30px;
  flex-direction: row;
}

.moreabout-image {
  position: relative;
  top: -16px;
  margin-right: 25px;
  padding-top: 0px;
}

.Bio-Logo,
.Motivations-Logo,
.Frustrations-Logo {
  height: 120px;
}

.moreabout-text ul {
  margin-top: 10px;
  font-family: "PT Sans";
  color: #274472;
}

.moreabout-text li {
  margin-bottom: 5px;
}

@media screen and (max-width: 680px) {
  .MoreAbout {
    margin: 0px;
    border-radius: 10px;
    padding: 25px 15px 15px;
    background-color: #a3cde4;
  }

  .MoreAbout h2 {
    text-align: center;
  }
  
  .MoreAbout h3 {
    font-size: 22px;
  }

  .more-about {
    display: none;
  }
  .more-about-mobile {
    margin-top: 10px;
    display: block;
  }

  .Bio-mobile,
  .Motivations-mobile,
  .Frustrations-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #274472;
    padding: 0 5px;
  }

  .Bio-Logo,
  .Motivations-Logo,
  .Frustrations-Logo {
    height: 40px;
    margin-right: 10px;
  }

  .more-about-content-left {
    display: flex;
    align-items: center;
  }

  .more-about-dropdown-icon {
    transform: scale(0.75);
  }

  .more-about-dropdown-icon.flipped {
    transform: rotate(180deg) scale(0.75);
  }

  .bio-mobile-list, .motivations-mobile-list, .frustrations-mobile-list {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .bio-mobile-list.active, .motivations-mobile-list.active, .frustrations-mobile-list.active {
    display: block;
  }
}
