.login-page {
  /* display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  font-family: "PT Sans", sans-serif;
}

.login-page-navbar {
  /* display: flex; */
  height: 50px;
  width: 100%;
  align-items: center;
  text-align: center;
  border: #000 1px solid;
  background-color: #ffd154;
}

.login-page-navbar h1 {
  margin-left: 5%;
  font-family: "DM Serif Display";
  font-size: 16pt;
  font-weight: 900;
  color: #274472;
}

.login-page-header-reg {
  font-size: 30px;
  vertical-align: top;
}

.login-page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 10%;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.login-page-header {
  position: relative;
  margin-bottom: 10px;
  font-size: 50px;
  font-family: "DM Serif Display";
  font-weight: 400;
  color: #274472;
}

.login-page-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-page-form p {
  margin-bottom: 10px;
  font-size: 25px;
  font-family: "PT Sans", sans-serif;
  color: #274472;
}

.login-page-form label {
  position: relative;
  left: -145px;
  top: 10px;
  font-size: 14px;
  font-family: "PT Sans";
  color: #274472;
}

.login-page-form input {
  width: 339.1px;
  padding: 12px 20px;
  margin-bottom: 40px;
  font-size: 1.5rem;
  font-family: "PT Sans";
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}

.login-page-form input:focus {
  outline: none;
}

.login-page-form-submit-btn {
  width: 220px;
  height: 60px;
  padding: 12px 20px;
  font-size: 15px;
  font-family: "PT Sans";
  font-weight: bold;
  cursor: pointer;
  border: none;
  background-color: #397da2;
  color: #ffffff;
}

.page-footer {
  margin: 50px 0;
  text-align: center;
}

.page-footer span {
  text-align: center;
  color: #91a3a7;
  font-family: "Acumin Pro", sans-serif;
  font-size: 15px;
}

.page-footer span a {
  text-align: center;
  color: #91a3a7;
  font-family: "Acumin Pro", sans-serif;
  font-size: 15px;
  text-decoration: none;
}

.page-footer span a:visited {
  color: #91a3a7;
  text-decoration: none;
}

@media screen and (min-width: 1800px) {
  .login-page-container {
    margin-bottom: 250px;  
  }

  .login-page-footer {
    bottom: 100px;
  } 
}


