.about-us {
  margin-top: 50px;
}

.about-us-tabs-container {
  display: block;
}

.about-us-accordion-container {
  display: none;
}

.nav-tabs {
  border-style: hidden;
  z-index: 0;
  position: relative;
  top: 2px;
}

.nav-tabs li:not(:last-child) {
  margin-right: .2em;
}

.nav-link.active {
  z-index: 2;
}

.nav-tabs .nav-link {
  font-size: 1.5em;
  text-align: left;
  padding-left: 1.5em;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  padding: 0.7em 1.5em;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #397da2;
  color: #FCFBFA;
  font-family: "DM Serif Display", serif;
  font-weight: 5=400;
}

.nav-tabs .nav-item .nav-link.active {
  background-color: #a3cde4;
  border-style: hidden;
  color: #274472;
}

.tab-content {
  background-color: #a3cde4;
  padding: 3em;
  font-family: "PT Sans";
  position: relative;
  z-index: 1;
  white-space: pre-wrap;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.about-us .accordion {
  background-color: #397DA2;
  border-radius: 20px;
}

.about-us .accordion .about-us-title-container button {
  padding: 10px 20px;
  width: 100%;
  background: none;
  border: none;
  color: #FCFBFA;
  font-size: 20pt;
  font-family: 'DM Serif Display', serif;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.about-us .accordion .about-us-showing {
  background-color: #A3CDE4;
  border-radius: 20px;
}

.about-us .accordion .about-us-showing .about-us-title-container button {
  color: #274472;
}

.about-us .accordion-collapse {
  color: #274472;
  font-family: 'PT Sans', sans-serif;
  padding: 0px 15px 15px 15px;
  line-height: 2em;
}

.about-us-showing .about-us-dropdown-icon {
  transform: rotate(180deg);
}

@media screen and (max-width: 620px) {
  .about-us-tabs-container {
    display: none;
  }
  .about-us-accordion-container {
    display: block;
  }
}