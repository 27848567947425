.KeyValueProps {
  padding: 1.5em;
  border-radius: 10px;
  background-color: #397da2;
}

.KeyValueProps h1 {
  color: #ffd154;
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  font-size: 28px;
}

.KeyValueProps h2 {
  color: #3a5157;
  padding-bottom: 1em;
  font-size: 20px;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  color: white;
}

.KeyValueProps ol {
  list-style-type: none;
  counter-reset: listItem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 1em 2.5em;
  padding-left: 1em;
}

.KeyValueProps li:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
}
.KeyValueProps li:nth-child(2) {
  grid-column: 1;
  grid-row: 2;
}
.KeyValueProps li:nth-child(3) {
  grid-column: 1;
  grid-row: 3;
}
.KeyValueProps li:nth-child(4) {
  grid-column: 2;
  grid-row: 1;
}
.KeyValueProps li:nth-child(5) {
  grid-column: 2;
  grid-row: 2;
}
.KeyValueProps li:nth-child(6) {
  grid-column: 2;
  grid-row: 3;
}

.KeyValueProps li {
  position: relative;
  color: white;
  padding-left: 0.5em;
  padding-right: 0.1em;
  padding-block: 0.3em;
  font-size: 18px;
  font-family: "PT Sans", sans-serif;
}

.KeyValueProps li::before {
  content: counter(listItem);
  counter-increment: listItem;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: -20px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 4px;
  font-family: "DM Serif Display", serif;
  background-color: white;
  color: #397da2;
}

.KeyValueProps-Logo {
  height: 3em;
  margin-bottom: 1em;
}

.read-more-btn {
  display: none;
  background-color: #ffd154;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #397da2;
  cursor: pointer;
  font-family: "PT Sans", sans-serif;
}

@media screen and (max-width: 720px) {
  .KeyValueProps {
    padding: 1em;
  }
  .KeyValueProps ol {
    display: block;
  }
  .KeyValueProps li {
    margin-top: 10px;
  }
}
