.UserPersonas {
  margin-top: 20px;
}

.userpersonas-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #397da2;
}

.UserPersonas-Logo {
  height: 200px;
}

.userpersonas-text {
  margin-left: 30px;
}

.userpersonas-container h3 {
  color: white;
  font-family: "PT Sans", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 4px;
}

.userpersonas-container h4 {
  color: white;
  font-family: "PT Sans";
  font-size: 22px;
  margin-bottom: 15px;
}

.userpersonas-container p {
  color: white;
  font-family: "PT Sans";
  font-size: 16px;
}

@media screen and (max-width: 680px) {
  .userpersonas-container {
    display: block;
    text-align: center;
    border-radius: 10px;
    background-color: #a3cde4;
    padding: 15px;
  }

  .UserPersonas-Logo {
    height: 120px;
  }

  .userpersonas-text {
    margin-left: 0;
  }

  .userpersonas-container h3 {
    color: #274472;
  }

  .userpersonas-container h4 {
    color: #274472;
  }
  .userpersonas-container p {
    color: #274472;
  }
}
