.admin-page-add-container {
  width: 100%;
}

.admin-page-add-form-container {
  width: 100%;
  background-color: transparent;
}

.admin-page-add-form {
  padding: 20px;
  width: 100%;
  font-family: "Gothic A1";
}

.add-user-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  width: 100%;
}

.admin-page-add-form input {
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 10px;
  width: 100%;
}

.admin-page-add-form input:focus {
  outline-width: 0;
}

.add-user-info-container select {
  /* styling */
  background-color: white;
  border: thin solid black;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

.add-user-info-container select:focus {
  outline-width: 0;
}

.admin-add-btn {
  width: 100px;
  /* height: 100%; */
  color: #284159;
  font-weight: bold;
  font-family: "Gothic A1";
  border-radius: 10px;
  background-color: #d5e7f1;
  border: solid 1px #284159;
}

.submit-user-btn {
  color: #284159;
  border: solid 1px #284159;
}

.close-user-btn {
  color: #ff3131;
  border: solid 1px #ff3131;
}
