.super-admin-page {
  background-color: white;
}

.super-admin-page-container {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  margin-top: 2em;
}

.super-admin-page-container-header {
  background-color: #397DA2;
  align-items: center;
  width: 100%;
  padding: 5px 20px;
}

.super-admin-page-container h1 {
  font-size: 2.5rem;
  color: white;
  font-weight: 400;
  font-family: "Gothic A1 Light", serif;
  display: inline;
  vertical-align: middle;
}

.header-image {
  width: 115px;
  height: 100px;
  display: inline;
}

.super-admin-page-add-button {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  margin: 10px auto 50px;
  align-items: center;
  justify-content: center;
  background-color: #d5e7f1;
  border-radius: 10px;
  border: none;
}
