.CoreStrategies {
  background-color: #f8feff;
  margin-top: 10px;
  margin-bottom: 45px;
}

.CoreStrategies .corestrategies-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #A3CDE4;
  padding: 10px 20px;
  border-radius: 15px;
}

.Core-Strategies-Logo {
  height: 100px;
}

.corestrategies-image {
  margin-right: 15px;
}

.CoreStrategies h1 {
  color: #274472;
  font-family: "DM Serif Display", serif;
  font-size: 32px;
  margin: 0;
}

.CoreStrategies .corestrategies-list {
  padding: 35px 15px 15px;
  max-width: 100%;
  background-color: #E4F0F7;
  border-radius: 15px;
}

.CoreStrategies ol li {
  padding-bottom: 12px;
  color: #274472;
}

@media screen and (max-width: 600px) {
  .CoreStrategies .corestrategies-container {
    padding: 10px;
  }
  .corestrategies-list ol {
    padding-left: 20px;
  }
}