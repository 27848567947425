.HomePage {
  display: "flex";
}

.content-container {
  max-width: 1000px;
  margin: 0 auto;
  display: block;
}

.welcome-header {
  font-family: "Gothic A1 Light", sans-serif;
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
}

.page-footer {
  display: block;
  margin: 50px 0;
  text-align: center;
}

.page-footer-mobile {
  display: none;
}

.page-footer span {
  text-align: center;
  color: #91a3a7;
  font-family: "PT Sans", sans-serif;
  font-size: 15px;
}

.page-footer span a {
  text-align: center;
  color: #91a3a7;
  font-family: "PT Sans", sans-serif;
  font-size: 15px;
  text-decoration: none;
}

.page-footer span a:visited {
  color: #91a3a7;
  text-decoration: none;
}

.page-footer-mobile {
  padding: 20px 0;
  background-color: #FFD154;
  margin-top: 30px;
}

.page-footer-mobile a {
  display: block;
  text-align: center;
  color: #274472;
  font-family: "PT Sans", sans-serif;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 10px;
}

.page-footer-mobile a:visited {
  color: #274472;
  text-decoration: none;
}

@media screen and (max-width: 680px) {
  .page-footer {
    display: none;
  }
  .page-footer-mobile {
    display: block;
  }
}