.KeyPerformanceIndicators {
  margin-bottom: 50px;
}

.KeyPerformanceIndicators .kpi-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #397da2;
  padding: 10px 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.Key-Performance-Indicators-Logo {
  height: 100px;
}

.kpi-image {
  margin-right: 15px;
}

.KeyPerformanceIndicators h1 {
  color: #fcfbfa;
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  font-size: 30px;
  margin: 0;
}

.kpi-mobile {
  display: none;
}

.kpi-table {
  width: 100%;
  border: none;
  font-family: 'PT Sans', sans-serif;
  color: #274472;
}

.kpi-table td {
  border: 1px solid #274472;
  color: #274472;
  padding: 18px 30px;
}

.kpi-table td:first-child {
  border-left: none;
}

.kpi-table td:last-child {
  border-right: none;
}

.kpi-table tr:first-child td {
  border-top: none;
}

.kpi-table tr:last-child td {
  border-bottom: none;
}

.kpi-table td.kpi {
  font-weight: bold;
  border-right: 1px solid #397da2;
}

.kpi-table td.objective {
  border-right: 1px solid #397da2;
}

.kpi-row-mobile {
  display: none;
}

@media screen and (max-width: 680px) {
  .KeyPerformanceIndicators h1 {
    color: #fcfbfa;
    font-family: "DM Serif Display", serif;
    font-weight: 400;
    font-size: 25px;
    margin: 0;
  }

  .kpi-table {
    display: none;
  }

  .kpi-row-mobile {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-bottom: 1px solid #274472;
  }

  .kpi-row-mobile:last-child {
    border-bottom: none;
  }

  .kpi-and-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  .kpi-mobile {
    display: flex;
    color: #274472;
    font-family: "PT Sans", sans-serif;
  }

  .key-performance-dropdown-icon.flipped {
    transform: rotate(180deg);
  }

  .kpi-content-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .kpi-details-mobile {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .kpi-details-mobile.active {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }

  .objective-mobile {
    font-family: 'PT Sans', sans-serif;
    color: #274472;
  }
  .date-mobile {
    font-family: 'PT Sans', sans-serif;
    color: #274472;
  }
}

@media screen and (max-width: 600px) {
  .KeyPerformanceIndicators .kpi-container {
    padding: 10px;
  }
}