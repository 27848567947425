.super-admin-page-search-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  margin-bottom: 10px;
}

.super-admin-page-search-container input {
  width: 300px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 20px;
}

.super-admin-page-search-container input:focus {
  outline-width: 0;
}
