.goals-heading-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.goals-image {
  height: 60px;
}

.overarching-goals .overarchinggoals-heading {
  padding-left: 0;
}

.goals-heading {
  color: #397da2;
  font-family: "DM Serif Display", serif;
  font-size: 26px;
  margin: 0;
}

.goals-list-container ul {
  padding-left: 30px;
}

.goals-list-container ul li {
  list-style-type: square;
  font-family: "PT Sans", sans-serif;
  padding-left: 0;
  padding-bottom: 12px;
  color: #274472;
  font-size: 18px;
}

@media screen and (max-width: 680px) {
  .goals-heading-container {
    margin-top: 20px;
    display: block;
  }
  
  .goals-list-container {
    width: 100%;
    text-align: left;
  }
  .goals-image-container {
    align-content: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-right: 0;
  }
  .goals-image {
    height: 75px;
    width: 75px;
    margin-right: 0;
  }
  .goals-heading {
    margin-bottom: 10px;
  }
  
}
