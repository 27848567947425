.KeyMessaging {
  margin: 50px 0;
}

.KeyMessaging h2 {
  color: #274472;
  font-family: "DM Serif Display", serif;
  font-size: 30px;
  margin-bottom: 25px;
}

.key-messaging-table {
  width: 100%;
  background-color: #397da2;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
}

.key-messaging-table-mobile {
  display: none;
}

.key-messaging-table th {
  color: #fcfbfa;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  padding: 12px 30px;
  color: #fcfbfa;
  text-align: center;
}

.key-messaging-table td {
  border-bottom: 1px solid #397da2;
  color: #274472;
  padding: 18px 30px;
  background-color: white;
}

.key-messaging-table td.painpoint {
  text-align: center;
  width: 30%;
  font-size: 18px;
  font-family: "PT Sans";
  border-right: 1px solid #397da2;
}

.key-messaging-table td.valueprop {
  font-size: 16px;
  font-family: "PT Sans", sans-serif;
}

.key-messaging-table tr:last-child td {
  border-bottom: none;
}

@media screen and (max-width: 680px) {
  .KeyMessaging {
    margin: 0px;
    border-radius: 10px;
  }

  .KeyMessaging h2 {
    text-align: center;
    padding: 0 10px;
    margin: 30px 0 20px;
  }

  .key-messaging-table {
    display: none;
  }

  .key-messaging-table-mobile {
    display: table;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  .key-messaging-details-mobile {
    border-bottom: 1px solid #397da2;
  }

  .key-messaging-details-mobile:last-child {
    border-bottom: none;
  }

  .painpoint-toggle-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    cursor: pointer;
  }

  .painpoint-mobile {
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #274472;
  }

  .key-messaging-dropdown-icon.flipped {
    transform: rotate(180deg);
  }

  .key-messaging-mobile {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #274472;
  }

  .key-messaging-mobile.active {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
  }
}
